/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React, { useContext, useEffect } from "react"

import RootElement from "./src/components/root-element"
import { StoreContext } from "./src/store"

// wrapRootElement

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}

export const wrapPageElement = ({ element }) => {
  const { setColorScheme } = useContext(StoreContext)

  useEffect(() => {
    setColorScheme(element.type.__COLOR_SCHEME__)
  }, [element.type.__COLOR_SCHEME__])

  return element
}
