import { css } from "styled-components"
import { rem } from "polished"

const widths = {
  xxxnarrow: 640,
  xxnarrow: 768,
  xnarrow: 1024,
  narrow: 1152,
  normal: 1366,
  wide: 1440,
}

const gutter = 40

const container = (maxWidth = widths.normal, { nested = false } = {}) => css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${maxWidth &&
  css`
    max-width: ${rem(
      typeof maxWidth === `string` ? widths[maxWidth] : maxWidth
    )};
  `}

  ${!nested &&
  css`
    padding-left: ${rem(gutter)};
    padding-right: ${rem(gutter)};

    @media ${(props) => props.theme.mediumDown} {
      padding-left: ${rem(gutter / 2)};
      padding-right: ${rem(gutter / 2)};
    }
  `}
`

export default container
