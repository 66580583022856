export default {
  white: `#fff`,
  black: `#2b2722`,
  grey: `#6a655d`,
  greyLight: `#a49d92`,
  orangeDarkest: `#6c3100`,
  orange: `#bf5600`,
  orangeLight: `#ffeccf`,
  orangeLightest: `#f9f8f2`,
}
