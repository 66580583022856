import React from "react"
import { ThemeProvider } from "styled-components"

import { StoreProvider, StoreConsumer } from "../../store"
import theme from "../../theme"

export default function RootElement({ children, ssrColorScheme }) {
  return (
    <StoreProvider>
      <StoreConsumer>
        {({ colorScheme }) => (
          <ThemeProvider
            theme={{ ...theme, colorScheme: colorScheme || ssrColorScheme }}
          >
            {children}
          </ThemeProvider>
        )}
      </StoreConsumer>
    </StoreProvider>
  )
}
