import React, { useState } from "react"

const StoreContext = React.createContext()
const StoreConsumer = StoreContext.Consumer

const StoreProvider = ({ children }) => {
  const [colorScheme, setColorScheme] = useState(``)

  return (
    <StoreContext.Provider
      value={{
        colorScheme,
        setColorScheme,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export { StoreContext, StoreProvider, StoreConsumer }
