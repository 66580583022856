import mq from "./mq"
import fonts from "./fonts"
import colors from "./colors"
// import zindex from "./zindex"
// import easings from "./easings"
// import animations from "./animations"
import container from "./container"

export default {
  mq,
  fonts,
  colors,
  // zindex,
  // easings,
  // animations,
  container,
}
